<template>
  <v-card elevation="0" class="pa-0 mt-3 mb-4 item-card flex-grow-1" :class="cardClass" :color="isSuccess ? '#ECECEC' : ''">
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col cols="9">
          <v-row no-gutters>
            <template v-for="(dataItem) in data">
              <v-col :key="dataItem.id" cols="4" v-if="dataItem.text">
                <div class="py-2 pl-3">{{ dataItem.text }}</div>
              </v-col>
              <v-col 
                :key="dataItem.id"
                :cols="(dataItem.valueCol || 8) + (dataItem.text ? 0 : 4)">
                <div :class="[dataItem.class, !dataItem.text ? 'py-2 pl-3' : 'py-2 pr-0']">
                  {{ dataItem.value }}
                  <v-icon v-if="dataItem.phones && dataItem.phones.length > 0" color="primary" :id="`phones-activator-${orderNo}`">fa-solid fa-phone</v-icon>
                  <v-menu :activator="`#phones-activator-${orderNo}`" transition="scale-transition">
                    <v-list>
                      <v-list-item
                        v-for="(phone, index) in dataItem.phones"
                        :key="index"
                        :value="index"
                      >
                        <v-list-item-title v-if="index === 0" @click.stop=""><a :href="`tel:${phone}`">主要：{{ phone }}</a></v-list-item-title>
                        <v-list-item-title v-else @click.stop=""><a :href="`tel:${phone}`">備用{{ index }}：{{ phone }}</a></v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <v-col cols="3" v-if="qrcodeLink" @click.stop="qrcodeShow = true" class="d-flex flex-column justify-center align-center p-1">
          <p v-show="isSuccess" class="mb-1 primary--text" style="font-size: 13px;">{{ arrivalAtFormat }} 已簽收</p>
          <qrcode-vue
            :value="qrcodeLink"
            :size="80"/>
          <p class="mb-1" style="font-size: 13px;">簽收QR Code</p>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay v-if="qrcodeShow" :opacity="0.7"></v-overlay>
    <v-dialog
      v-model="qrcodeShow"
      :value="true"
      :contained="true"
      :width="200"
      @click:outside="qrcodeShow = false"
    >
      <qrcode-vue
        :value="qrcodeLink"
        :size="200"/>
    </v-dialog>
  </v-card>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import Moment from 'moment'

export default {
  components: {
    QrcodeVue
  },
  data: () => ({
    qrcodeShow: false
  }),
  props: {
    type: {
      type: String,
      default: "primary",
      validator(value) {
        return ["primary", "secondary", 'lighten', 'dark', 'error'].includes(value)
      },
    },
    status: {
      type: String,
      default: '',
    },
    arrivalAt: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [{ text: "label", value: "value" }],
    },
    actionText: {
      type: String,
    },
    qrcodeLink: {
      type: String,
    },
    action: {
      type: Function,
      default: () => {},
    },
    orderNo: {
      type: String,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardClass() {
      if (this.type === "primary") return "item-card--primary"
      if (this.type === "secondary") return "item-card--secondary"
      if (this.type === "lighten") return "item-card--lighten"
      if (this.type === "dark") return "item-card--dark"
      if (this.type === "error") return "item-card--error"
      return ""
    },
    arrivalAtFormat() {
      console.log(this.arrivalAt)
      return this.arrivalAt ? Moment(this.arrivalAt).format('HH:mm') : null
    },
    isSuccess() {
      return ['finished', 'request_return'].includes(this.status)
    }
  },
};
</script>

<style lang="sass">
.linerp-layout
  .item-card
    background: #ffffff
    color: #122A47
    border: 1px solid #ECECEC !important
    border-radius: 5px !important
    &-btn
      font-size: 14px
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      border-radius: 5px 0 5px 0
  .item-card--primary
    .item-card-btn
      background: #009775
      color: #FFFFFF
  .item-card--secondary
    .item-card-btn
      background: #ffffff
      color: #009775
      border: 1px solid #009775
.provider-store-layout
  .item-card
    background: #ffffff
    color: #122A47
    border: 1px solid #ECECEC !important
    border-radius: 5px !important
    &-btn
      font-size: 14px
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      border-radius: 5px 0 5px 0
  .item-card--primary
    .item-card-btn
      background: #006DE0
      color: #FFFFFF
  .item-card--secondary
    .item-card-btn
      background: #ffffff
      color: #006DE0
      border: 1px solid #006DE0

.item-card--lighten
  .item-card-btn
      background: #ffffff
      color: #00E086
      border: 1px solid #00E086
.item-card--dark
  .item-card-btn
      background: #ffffff
      color: #122A47
      border: 1px solid #122A47
.item-card--error
  .item-card-btn
      background: #ffffff
      color: #E00000
      border: 1px solid #E00000

</style>